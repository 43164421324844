<template>
  <router-view />
  <ModalsContainer />
</template>

<style>

</style>

<script setup>
import {ModalsContainer} from "vue-final-modal";

</script>
<script>

export default {
  name: 'App',
}

</script>
