<!-- ConnectionStatus.vue -->
<template>
  <div >
    <p>Stato della rete: {{ networkStatus }}</p>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

export default ({
  name: 'NetworkStatus',
  setup() {
    const networkStatus = ref(navigator.onLine ? 'Online' : 'Offline');

    function updateNetworkStatus() {
      networkStatus.value = navigator.onLine ? 'Online' : 'Offline';
    }

    onMounted(() => {
      window.addEventListener('online', updateNetworkStatus);
      window.addEventListener('offline', updateNetworkStatus);
    });

    onUnmounted(() => {
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
    });

    return {
      networkStatus,
    };
  },
});
</script>
