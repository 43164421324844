<script setup lang="ts">
  import { defineProps } from 'vue';

  const props = defineProps<{
    title: string,
    description: string,
    image: string,
  }>()
</script>

<template>
  <div class="max-w-sm rounded overflow-hidden shadow-lg m-10 cursor-pointer">
    <img class="w-full" :src="'/img/' + props.image" :alt='props.title'>
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">{{ props.title }}</div>
      <p class="text-gray-700 text-base">
        {{ props.description }}
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>
