import {createRouter, createWebHistory} from 'vue-router'
import HomeView from "@/views/HomeView.vue";

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: HomeView,
            meta: {
                requiresAuth: true
            }
        },
        {
            name: 'login',
            path: '/login',
            component: () => import('@/views/LoginView.vue'),
        },
        {
            name: 'odsHome',
            path: '/ods',
            component: () => import('@/views/ods/OdsHome.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/ods/:orderId',
            component: () => import('@/views/ods/OdsView.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/closedods',
            component: () => import('@/views/ods/ClosedOdsView.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/allods',
            component: () => import('@/views/ods/AllOdsView.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            name: 'equipmentHome',
            path: '/equipment',
            component: () => import('@/views/equipment/EquipmentHomeView.vue'),
            meta: {
                requiresAuth: true
            }
        },
        {
            name: 'editEquipment',
            path: '/editequipment/:equipmentId',
            component: () => import('@/views/equipment/EditEquipmentView.vue'),
            meta: {
                requiresAuth: true
            }
        }
    ],
})
